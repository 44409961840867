const ADMIN = 'ADMIN'
const BASIC = 'BASIC'
const FULL = 'FULL'
const LIMITED = 'LIMITED'

const USER_ROLES = [ADMIN, BASIC, FULL, LIMITED]

// Developers
const DEVELOPER_ROLE_CHOICES = [
  { id: ADMIN, value: ADMIN, name: 'Admin' },
  { id: LIMITED, value: LIMITED, name: 'Limited Access' },
  { id: FULL, value: FULL, name: 'Full Access' },
]

// Vendor/Integrators
const CHANNEL_PARTNER_ROLE_CHOICES = [
  { id: ADMIN, value: ADMIN, name: 'Admin' },
  { id: BASIC, value: BASIC, name: 'Basic' },
]

const ACCESS_SETTINGS = 'core.can_access_settings'

const PROPERTY_MANAGER = 'PROPERTY_MANAGER'
const PROPERTY_DEVELOPER = 'PROPERTY_DEVELOPER'
const PROJECT_MANAGER = 'PROJECT_MANAGER'
const HOA_CONDO_BOARD = 'HOA_CONDO_BOARD'
const OTHER = 'OTHER'

const TEAM_ROLE_CHOICES = [
  { id: PROPERTY_MANAGER, value: PROPERTY_MANAGER, name: 'Property Manager' },
  { id: PROPERTY_DEVELOPER, value: PROPERTY_DEVELOPER, name: 'Property Developer' },
  { id: PROJECT_MANAGER, value: PROJECT_MANAGER, name: 'Project Manager' },
  { id: HOA_CONDO_BOARD, value: HOA_CONDO_BOARD, name: 'HOA or Condo Board' },
  { id: OTHER, value: OTHER, name: 'Other' },
]

export default {
  ADMIN,
  BASIC,
  USER_ROLES,
  DEVELOPER_ROLE_CHOICES,
  CHANNEL_PARTNER_ROLE_CHOICES,
  ACCESS_SETTINGS,
  PROPERTY_MANAGER,
  PROPERTY_DEVELOPER,
  PROJECT_MANAGER,
  HOA_CONDO_BOARD,
  OTHER,
  TEAM_ROLE_CHOICES,
}
