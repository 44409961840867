import axios from 'axios'
import CSRF from '@/services/csrf'
import Auth from '@/services/auth'

/**
 *   Get the axios API client.
 *   This conveniently sets the `baseURL` and `headers` for the API client,
 *   so that we don't have to do this in every function that needs to call
 *   the API.
 *
 *   @returns {object} - An instance of the axios API client.
 */
export function apiClient() {
  const { location } = window
  return axios.create({
    baseURL: `${location.protocol}//${location.host}` + '/api',
    headers: {
      ...CSRF.getHeaders(),
      ...Auth.getHeaders(),
    },
  })
}

export function apiUploader() {
  return axios.create({
    baseURL: `https://uploader.rewyre.com`,
    headers: {
      ...CSRF.getHeaders(),
      ...Auth.getHeaders(),
    },
  })
}

export function apiAutodesk(token) {
  return axios.create({
    baseURL: `https://developer.api.autodesk.com/oss/v2`,
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'arraybuffer',
  })
}
