import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { apiClient } from '@/services/client'
import { apiErrorHandler } from '../api'
import qs from 'qs'

export default class SystemAPI extends ModelAPI {
  static ENDPOINT = '/systems/'

  static FILTERS_MAP = {
    ...SystemAPI.FILTERS_MAP,
    // Custom filters
    offerings: ApiFilter.create({ key: 'offerings' }),
    vendor: ApiFilter.create({ key: 'vendor' }),
    state: ApiFilter.create({ key: 'state' }),
    integrator: ApiFilter.create({ key: 'integrator' }),
    excludeIntegrator: ApiFilter.create({ key: 'exclude_integrator' }),
    attributes: ApiFilter.create({ key: 'attributes' }),
    search: ApiFilter.create({ key: 'search' }),
    _upfrontCostMin: ApiFilter.create({ key: '_upfront_cost_min' }),
    _upfrontCostMax: ApiFilter.create({ key: '_upfront_cost_max' }),
    _monthlyCostMin: ApiFilter.create({ key: '_monthly_cost_min' }),
    _monthlyCostMax: ApiFilter.create({ key: '_monthly_cost_max' }),
    compatibleSystems: ApiFilter.create({ key: 'compatible_systems' }),
    vertical: ApiFilter.create({ key: 'offerings__vertical' }),
    serializeLite: ApiFilter.create({ key: 'serialize_lite' }),
  }

  get client() {
    return apiClient()
  }

  list({ filters = {}, pagination = {} }) {
    const url = this.constructor.ENDPOINT
    const filtersMap = this.constructor.FILTERS_MAP
    const options = {
      params: ApiFilter.buildParams(filtersMap, {
        ...filters,
        page: pagination.page,
        pageSize: pagination.size,
      }),
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    }
    return this.client
      .get(url, options)
      .then(response => response.data)
      .then(data => ({
        ...data,
        results: data.results.map(i => this.cls.fromAPI(i)),
      }))
  }

  patch(systemID, fields = [], excludeFields = []) {
    const url = `${SystemAPI.ENDPOINT}${systemID}/`
    let data = this.cls.toAPI(fields, excludeFields)
    return this.client
      .patch(url, data)
      .then(response => response.data)
      .then(this.fromAPI)
      .catch(apiErrorHandler({ apiName: 'Update System API error', rethrowErrors: true }))
  }

  async uploadImage(systemId, file, deletePrev = false) {
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }
    const formData = new FormData()
    formData.append('file', file)
    formData.append('id', systemId)
    formData.append('delete_previous', deletePrev)
    const API = '/systems-files/'
    const url = `${API}`
    return this.client.post(url, formData, config)
  }

  async uploadSystems(file, params) {
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    const required_params = {
      offerings: params['offering_ids'],
      vertical: params['vertical_id'],
      preview: params['preview'],
    }

    const formData = new FormData()
    formData.append('file', file)
    formData.append('params', JSON.stringify(required_params))
    const API = `/uploads/systems/`
    const url = `${API}`
    return this.client.post(url, formData, config)
  }
}
