import { SystemOptions } from '@/services/systems'

export const devButtons = [
  {
    name: 'Marketplace',
    link: 'SystemSearch',
  },
  {
    name: 'Portfolio',
    link: 'Portfolio',
  },
  {
    name: 'Transactions',
    link: 'DeveloperQuotes',
  },
]

export const unapprovedDevButtons = [
  {
    name: 'Marketplace',
    link: 'SystemSearch',
  },
  {
    name: 'Portfolio',
    link: 'Portfolio',
  },
]

export const vendorButtons = [
  {
    name: 'Portfolio',
    link: 'Portfolio',
  },
  {
    name: 'Systems',
    link: 'SystemSearch',
  },
  {
    name: 'Transactions',
    link: 'VendorDashboard',
  },
]

export const integratorButtons = [
  {
    name: 'Portfolio',
    link: 'Portfolio',
  },
  {
    name: 'Systems',
    link: 'MyProducts',
  },
  {
    name: 'All Systems',
    link: 'SystemSearch',
    requiredPermission: SystemOptions.VIEW_ALL_SYSTEMS,
  },
  {
    name: 'Transactions',
    link: 'IntegratorDashboard',
  },
]
