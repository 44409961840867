import store from '@/store'

export default {
  getHeaders() {
    if (!store.state.token) {
      return {}
    }
    return {
      Authorization: `Token ${store.state.token}`,
    }
  },
}

export function requireAuth(to, from, next) {
  // all logged in users can access the route
  // if not logged in, a user will be redirected to the login page
  if (!store.getters.isLoggedIn) {
    next({
      name: 'Login',
      query: { redirect: to.fullPath },
    })
  } else {
    next()
  }
}

export function requireAuthUserOnly(to, from, next) {
  // only regular users (not admins) can access this route
  // if not logged in, a user will be sent back to the login page
  // if logged in as an admin, the user will be redirected to the admin dashboard
  if (!store.getters.isLoggedIn) {
    next({
      name: 'Login',
      query: { redirect: to.fullPath },
    })
  } else if (store.getters.isAdmin) {
    next({
      name: 'DashboardAdmin',
    })
  } else if (!userHasPermission(to)) {
    next({
      name: 'Dashboard',
    })
  } else {
    next()
  }
}

export function requireDevArchUserOnly(to, from, next) {
  // only regular dev users (not admins) can access this route
  // if not logged in, a user will be sent back to the login page
  // if logged in as a vendor or integrator, the user will be redirected to their respective dashboard
  // if logged in as an admin, the user will be redirected to the admin dashboard
  if (!store.getters.isLoggedIn) {
    next({
      name: 'Login',
      query: { redirect: to.fullPath },
    })
  } else if (store.getters.isVendor || store.getters.isIntegrator) {
    next({
      name: 'Dashboard',
    })
  } else if (store.getters.isAdmin) {
    next({
      name: 'DashboardAdmin',
    })
  } else {
    next()
  }
}

export function requireApprovedDevArchUserOnly(to, from, next) {
  // only regular dev users (not admins) can access this route
  // if not logged in, a user will be sent back to the login page
  // if logged in as a vendor or integrator, the user will be redirected to their respective dashboard
  // if logged in as an admin, the user will be redirected to the admin dashboard
  if (!store.getters.isLoggedIn) {
    next({
      name: 'Login',
      query: { redirect: to.fullPath },
    })
  } else if (store.getters.isVendor || store.getters.isIntegrator || !store.getters.isApproved) {
    next({
      name: 'Dashboard',
    })
  } else if (store.getters.isAdmin) {
    next({
      name: 'DashboardAdmin',
    })
  } else {
    next()
  }
}

export function requireAuthVendorUserOnly(to, _from, next) {
  // only regular vendor users can access this route
  // if not logged in, a user will be sent back to the login page
  if (!store.getters.isLoggedIn) {
    next({
      name: 'Login',
      query: { redirect: to.fullPath },
    })
  } else if (!store.getters.isVendor) {
    next({
      name: 'Dashboard',
    })
  } else {
    next()
  }
}

export function requireAuthIntegratorUserOnly(to, _from, next) {
  // only regular integrator users can access this route
  // if not logged in, a user will be sent back to the login page
  if (!store.getters.isLoggedIn) {
    next({
      name: 'Login',
      query: { redirect: to.fullPath },
    })
  } else if (!store.getters.isIntegrator) {
    next({
      name: 'Dashboard',
    })
  } else {
    next()
  }
}

export function requireAuthApprovedUserOnly(to, from, next) {
  // only regular users (not admins) can access this route
  // if not logged in, a user will be sent back to the login page
  // if logged in as an admin, the user will be redirected to the admin dashboard
  if (!store.getters.isLoggedIn) {
    next({
      name: 'Login',
      query: { redirect: to.fullPath },
    })
  } else if (store.getters.isAdmin) {
    next({
      name: 'DashboardAdmin',
    })
  } else if (!store.getters.isApproved || !userHasPermission(to)) {
    next({
      name: 'Dashboard',
    })
  } else {
    next()
  }
}

export function requireAuthApprovedUserOrAdminUser(to, from, next) {
  // only regular users and admins can access this route
  // if not logged in, a user will be sent back to the login page
  // if not logged in as an approved user or admin, a user will be sent back to the login page
  if (!store.getters.isLoggedIn) {
    next({
      name: 'Login',
      query: { redirect: to.fullPath },
    })
  } else if (!store.getters.isAdmin && !store.getters.isApproved) {
    next({
      name: 'Dashboard',
    })
  } else {
    next()
  }
}

export function requireAuthAdminOnly(to, from, next) {
  // only admin users can access this route
  // if not logged in, a user will be sent back to the login page
  // if the user is not an admin, they will be sent back to the dashboard
  if (!store.getters.isLoggedIn) {
    next({
      name: 'Login',
      query: { redirect: to.fullPath },
    })
  } else if (!store.getters.isAdmin) {
    next({
      name: 'Dashboard',
    })
  } else {
    next()
  }
}

export function noAuth(to, from, next) {
  // no auth is required for this route, if they are logged in, they will be redirected to their respective dashboard pages
  if (store.getters.isLoggedIn) {
    if (store.getters.isAdmin) {
      next({
        name: 'DashboardAdmin',
      })
    }
    if (!store.getters.isAdmin) {
      next({
        name: 'Dashboard',
      })
    }
  } else {
    next()
  }
}

function userHasPermission(to) {
  if (!to.meta.requiredPermission) return true

  return store.getters.userHasPermission(to.meta.requiredPermission)
}
