import { ModelAPI } from '@thinknimble/tn-models'
import { apiClient } from '@/services/client'

export default class SoftwareHardwareAPI extends ModelAPI {
  static ENDPOINT = '/software-hardwares/'
  /**
   * ModelAPI contains methods for list and create (overridden here) and the FILTERS_MAP
   * You may override any of these methods by statically defining them here
   * e.g static FILTERS_MAP={...UserAPI.FILTERS_MAP, <FILTERS>}
   *      list({ filters = {}, pagination = {} }){
   *
   * }
   */

  static FILTERS_MAP = {
    ...SoftwareHardwareAPI.FILTERS_MAP,
  }

  get client() {
    return apiClient()
  }
}
