import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { apiClient } from '@/services/client'
import qs from 'qs'

export default class OfferingAPI extends ModelAPI {
  static ENDPOINT = '/offerings/'
  /**
   * ModelAPI contains methods for list and create (overridden here) and the FILTERS_MAP
   * You may override any of these methods by statically defining them here
   * e.g static FILTERS_MAP={...UserAPI.FILTERS_MAP, <FILTERS>}
   *      list({ filters = {}, pagination = {} }){
   *
   * }
   */

  static FILTERS_MAP = {
    ...OfferingAPI.FILTERS_MAP,
    search: ApiFilter.create({ key: 'search' }),
    vertical: ApiFilter.create({ key: 'vertical' }),
  }

  get client() {
    return apiClient()
  }

  list({ filters = {}, pagination = {} }) {
    const url = this.constructor.ENDPOINT
    const filtersMap = this.constructor.FILTERS_MAP
    const options = {
      params: ApiFilter.buildParams(filtersMap, {
        ...filters,
        page: pagination.page,
        pageSize: pagination.size,
      }),
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    }
    return this.client
      .get(url, options)
      .then(response => response.data)
      .then(data => ({
        ...data,
        results: data.results.map(i => this.cls.fromAPI(i)),
      }))
  }
}
