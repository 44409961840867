import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { apiClient } from '@/services/client'
import { apiErrorHandler } from '../api'

export default class ProductApi extends ModelAPI {
  static ENDPOINT = '/products/'
  /**
   * ModelAPI contains methods for list and create (overridden here) and the FILTERS_MAP
   * You may override any of these methods by statically defining them here
   * e.g static FILTERS_MAP={...UserAPI.FILTERS_MAP, <FILTERS>}
   *      list({ filters = {}, pagination = {} }){
   *
   * }
   */

  static FILTERS_MAP = {
    ...ProductApi.FILTERS_MAP,
    // Custom filters
    vertical: ApiFilter.create({ key: 'offering__vertical' }),
    vendor: ApiFilter.create({ key: 'vendor' }),
    integrator: ApiFilter.create({ key: 'integrators' }),
    useType: ApiFilter.create({ key: 'use_type' }),
    softwareHardware: ApiFilter.create({ key: 'software_hardware' }),
    productCategory: ApiFilter.create({ key: 'product_category' }),
    search: ApiFilter.create({ key: 'search' }),
    upfrontCostMin: ApiFilter.create({ key: 'fields__upfront_cost_min' }),
    upfrontCostMax: ApiFilter.create({ key: 'fields__upfront_cost_max' }),
    monthlyCostMin: ApiFilter.create({ key: 'fields__monthly_cost_min' }),
    monthlyCostMax: ApiFilter.create({ key: 'fields__monthly_cost_max' }),
  }

  get client() {
    return apiClient()
  }

  patch(productId, fields = [], excludeFields = []) {
    const url = `${ProductApi.ENDPOINT}${productId}/`
    let data = this.cls.toAPI(fields, excludeFields)
    return this.client
      .patch(url, data)
      .then(response => response.data)
      .then(this.fromAPI)
      .catch(apiErrorHandler({ apiName: 'Update Product API error', rethrowErrors: true }))
  }

  async uploadImage(productId, image) {
    const url = `${ProductApi.ENDPOINT}${productId}/upload-image/`
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }
    const formData = new FormData()
    formData.append('image', image)
    return this.client.post(url, formData, config)
  }

  async uploadProducts(file, params) {
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }

    const required_params = {
      vertical: params['vertical_id'],
      preview: params['preview'],
    }

    const formData = new FormData()
    formData.append('file', file)
    formData.append('params', JSON.stringify(required_params))
    const API = `/uploads/products/`
    const url = `${API}`
    return this.client.post(url, formData, config)
  }
}
