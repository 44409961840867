<template>
  <Button
    :label="btnText"
    :class="classes"
    :icon="icon"
    :iconPos="iconPosition"
    :loading="loading"
    :disabled="disabled"
    :outlined="outlined"
  >
    <slot />
  </Button>
</template>

<script lang="js">
import Button from 'primevue/button'

export default {
  props: {
    btnText: String,
    classes: String,
    icon: String,
    iconPosition: String,
    loading: Boolean,
    disabled: Boolean,
    outlined: Boolean,
  },
  components: {
    Button,
  },
}
</script>

<!-- To Use -->
<!-- Temporary fix. -->
<!-- Have to wrap button in a span (if inline) or div (if block) -->
<!--
<div @click="closeModal">
  <Button 
    classes="p-button-secondary p-button-block"
    btnText="Add Product"
    icon="pi pi-times"
    iconPosition="left" 
  />
</div>
-->
<!-- Submit a form -->
<!-- has to be within a <form> -->
<!-- 
  <Button 
    type="Submit"
    btnText="Submit"
    icon="pi pi-times"
    iconPosition="right" 
  /> 
-->
<!-- Link to another page -->
<!-- 
<router-link
  :to="{ name: 'SystemSearch' }"
  role="button"
  style="text-decoration: none"
>
  <Button classes="p-button" btnText="Go To Marketplace" />
</router-link>
-->
