// The Vue build version to load with the import command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'babel-polyfill'
import { createApp } from 'vue'

import App from './App'
import router from './router'
import store from './store'

import Hotjar from 'vue-hotjar'
import timeago from 'vue-timeago3'
import PrimeVue from 'primevue/config'
import '@/styles/base/_theme.scss'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import BadgeDirective from 'primevue/badgedirective'
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'
import Embed from 'v-video-embed'
const HOTJAR_ID = process.env.VUE_APP_HOTJAR
const app = createApp(App)

app.use(store)
app.use(router)
app.use(Hotjar, { id: HOTJAR_ID })
app.use(Embed)
app.config.globalProperties.$Autodesk = window.Autodesk
const timeagoOptions = {
  converterOptions: {
    includeSeconds: false,
  },
}

app.use(timeago, timeagoOptions)

// PrimeVue imports
app.use(PrimeVue)
app.use(ToastService)
app.directive('badge', BadgeDirective)
app.directive('tooltip', Tooltip)

// turn off noisy console [Vue warn]
app.config.warnHandler = () => {
  null
}
app.mount('#index-app')
