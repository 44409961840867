<template>
  <NavBar :buttons="buttons" />
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from '@/components/utils/NavBar'
import { devButtons, unapprovedDevButtons, vendorButtons, integratorButtons } from '@/router/links'
export default {
  name: 'NavBarLayout',
  components: {
    NavBar,
  },
  data() {
    return {
      devButtons,
      unapprovedDevButtons,
      vendorButtons,
      integratorButtons,
    }
  },

  computed: {
    ...mapGetters(['isVendor', 'isIntegrator', 'isDeveloper', 'isApproved', 'user']),
    buttons() {
      if (this.isApproved) {
        if (this.isVendor) {
          return vendorButtons
        } else if (this.isIntegrator) {
          return integratorButtons
        } else {
          return devButtons
        }
      } else {
        return this.isDeveloper ? this.unapprovedDevButtons : []
      }
    },
  },
}
</script>
