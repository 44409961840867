import Model, { fields } from '@thinknimble/tn-models'
import UseTypeAPI from './api'

export default class UseType extends Model {
  static api = UseTypeAPI.create(UseType)

  static id = new fields.IdField({ readOnly: true })
  static name = new fields.CharField()
  static datetimeCreated = new fields.CharField()
  static lastUpdated = new fields.CharField()
}
