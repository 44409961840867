<template>
  <div id="app">
    <Toast />
    <!-- Binding a key to the full path will remount a view if
        the detail endpoint changes-->
    <component v-bind:is="layout"></component>
  </div>
</template>

<script>
import SimpleLayout from '@/layouts/SimpleLayout'
import NavBarLayout from '@/layouts/NavBarLayout'
import { mapGetters } from 'vuex'
import Toast from 'primevue/toast'

export default {
  name: 'app',
  components: {
    SimpleLayout,
    NavBarLayout,
    Toast,
  },
  data() {
    return {
      simpleLayoutPaths: ['Login', 'ResetPassword', 'CompanySignup', 'Signup'],
    }
  },
  computed: {
    ...mapGetters(['layout']),
  },
  watch: {
    $route(to) {
      if (this.simpleLayoutPaths.includes(to.name)) {
        this.$store.dispatch('updateLayout', 'SimpleLayout')
      } else {
        this.$store.dispatch('updateLayout', 'NavBarLayout')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
