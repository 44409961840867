import Model, { fields } from '@thinknimble/tn-models'

import VendorAPI from './api'

export default class Vendor extends Model {
  static api = VendorAPI.create(Vendor)

  static id = new fields.IdField({ readOnly: true })
  static name = new fields.CharField()
  static logo = new fields.CharField()
  static url = new fields.CharField()
  static isApproved = new fields.BooleanField()
  static proposalCredits = new fields.IntegerField()
  static stripeAccountId = new fields.CharField()
  static datetimeCreated = new fields.CharField()
  static lastUpdated = new fields.CharField()
}
