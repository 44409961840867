import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { apiClient } from '@/services/client'

export default class CompanyApi extends ModelAPI {
  static ENDPOINT = '/companies/'
  /**
   * ModelAPI contains methods for list and create (overridden here) and the FILTERS_MAP
   * You may override any of these methods by statically defining them here
   * e.g static FILTERS_MAP={...UserAPI.FILTERS_MAP, <FILTERS>}
   *      list({ filters = {}, pagination = {} }){
   *
   * }
   */

  static FILTERS_MAP = {
    ...CompanyApi.FILTERS_MAP,
    search: ApiFilter.create({ key: 'search' }),
    offering: ApiFilter.create({ key: 'offering' }),
    system_offerings: ApiFilter.create({ key: 'system_offerings' }),
    system_verticals: ApiFilter.create({ key: 'system_verticals' }),
  }

  get client() {
    return apiClient()
  }

  async uploadImage(companyId, image) {
    const url = `${CompanyApi.ENDPOINT}${companyId}/upload-image/`
    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }
    const formData = new FormData()
    formData.append('image', image)
    return this.client.post(url, formData, config)
  }
}
