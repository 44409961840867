import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { User } from '@/services/users'
import options from '@/services/projects/options'
import {
  UPDATE_USER,
  UPDATE_USER_TOKEN,
  UPDATE_USER_ORGANIZATION_OWNER,
  UPDATE_NEW_PROPERTY,
  UPDATE_ACTIVE_STEP,
  RESET_NEW_PROPERTY,
  LOG_OUT,
  TOGGLE_MODAL,
  UPDATE_FILES,
  UPDATE_MODELS,
  UPDATE_MARKUPS,
  UPDATE_FILE_SELECTED,
  ADD_BANK_INFO,
  VERIFY_BANK_INFO,
  UPDATE_VERTICALS_AND_OFFERINGS,
  TOGGLE_ONBOARDING_PROGRESS,
  UPDATE_TOOLTIP_STEP,
  UPDATE_ONBOARDING_SETTING,
  SET_LAYOUT,
  UPDATE_PROPOSAL_SYSTEMS,
  UPDATE_PROPOSAL_ORDER,
  UPDATE_PROPOSAL,
} from './mutation-types'
import router from '../router'

const STORAGE_HASH = 'Vr88rPzt1C'
export const STORAGE_KEY = `rewyre-${STORAGE_HASH}`

const state = {
  user: null,
  token: null,
  newProject: {
    name: '',
    formattedAddress: '',
    lat: 0,
    long: 0,
    useTypes: [],
    buildType: '',
    userTypes: [],
    budget: 0,
    currency: 'USD',
    size: 0,
    sizeUnit: 'sq ft',
    status: options.STATUSES[0],
    urn: '',
    files: [],
  },
  activeStep: 0,
  modal: {
    type: null,
    id: null,
  },
  onboardingProgress: false,
  onboardingSetting: {
    open: true,
    steps: [],
    type: '',
  },
  tooltipStep: 1,
  files: [],
  fileSelected: {},
  models3D: [],
  markups: [],
  verticalsAndOfferings: {
    selectedOffering: null,
    selectedVertical: null,
    breadcrumbs: [{ value: null, id: 0, label: 'All categories' }],
    tiles: [],
    step: 0,
  },
  layout: 'SimpleLayout',
  proposalSystems: [],
  currentProposalOrder: 0,
  proposal: {},
}

const mutations = {
  [UPDATE_USER]: (state, payload) => {
    state.user = payload
  },
  [UPDATE_USER_TOKEN]: (state, payload) => {
    state.token = payload
  },
  [UPDATE_USER_ORGANIZATION_OWNER]: state => {
    if (state.user.is_developer) {
      state.user.company_ref.owner = state.user.id
    } else if (state.user.is_vendor) {
      state.user.vendor_ref.owner = state.user.id
    } else if (state.user.is_integrator) {
      state.user.integrator_ref.owner = state.user.id
    }
  },
  [UPDATE_MARKUPS]: (state, payload) => {
    state.markups = payload.markups
  },
  [UPDATE_FILES]: (state, payload) => {
    state.files = payload.files
  },
  [UPDATE_MODELS]: (state, payload) => {
    state.models3D = payload.models3D
  },
  [UPDATE_FILE_SELECTED]: (state, payload) => {
    state.fileSelected = payload.fileSelected
  },
  [UPDATE_NEW_PROPERTY]: (state, payload) => {
    state.newProject = { ...state.newProject, ...payload }
  },
  [UPDATE_ACTIVE_STEP]: (state, payload) => {
    state.activeStep = payload
  },
  [RESET_NEW_PROPERTY]: state => {
    state.activeStep = 0
    state.newProject = {
      name: '',
      formattedAddress: '',
      lat: 0,
      long: 0,
      useTypes: [],
      buildType: '',
      userTypes: [],
      budget: 0,
      currency: 'USD',
      size: 0,
      sizeUnit: 'sq ft',
      status: options.STATUSES[0],
      urn: '',
      files: [],
      objective: '',
      requirements: '',
      businessOutcomes: '',
      isPrivate: false,
      timeline: '',
      verticals: [],
    }
  },
  [LOG_OUT]: state => {
    state.user = null
    state.token = null
  },
  [TOGGLE_MODAL]: (state, payload) => {
    state.modal = payload
  },
  [TOGGLE_ONBOARDING_PROGRESS]: (state, payload) => {
    state.onboardingProgress = payload
  },
  [ADD_BANK_INFO]: (state, payload) => {
    state.user.company_ref.stripe_bank_id = payload
  },
  [VERIFY_BANK_INFO]: (state, payload) => {
    state.user.company_ref.stripe_bank_verified = payload
  },
  [UPDATE_VERTICALS_AND_OFFERINGS]: (state, payload) => {
    state.verticalsAndOfferings = payload
  },
  [SET_LAYOUT]: (state, payload) => {
    state.layout = payload
  },
  [UPDATE_TOOLTIP_STEP]: (state, payload) => {
    state.tooltipStep = payload
  },
  [UPDATE_ONBOARDING_SETTING]: (state, payload) => {
    state.onboardingSetting = payload
  },
  [UPDATE_PROPOSAL_SYSTEMS]: (state, payload) => {
    state.proposalSystems = payload
  },
  [UPDATE_PROPOSAL_ORDER]: (state, payload) => {
    state.currentProposalOrder = payload
  },
  [UPDATE_PROPOSAL]: (state, payload) => {
    state.proposal = payload
  },
}

const actions = {
  validateToken({ state }) {
    if (!state.token) {
      return false
    }
    return User.api
      .validateToken(state.token)
      .then(data => !data.needsVerification)
      .catch(() => {
        return false
      })
  },
  setUser({ commit }, user) {
    commit(UPDATE_USER, user)
    commit(UPDATE_USER_TOKEN, user.token)
  },
  updateUser({ commit }, payload) {
    commit(UPDATE_USER, payload)
  },
  updateUserToken({ commit }, payload) {
    commit(UPDATE_USER_TOKEN, payload)
  },
  updateUserOrganizationOwner({ commit }) {
    commit(UPDATE_USER_ORGANIZATION_OWNER)
  },
  updateMarkups({ commit }, payload) {
    commit(UPDATE_MARKUPS, payload)
  },
  updateFiles({ commit }, payload) {
    commit(UPDATE_FILES, payload)
  },
  updateFileSelected({ commit }, payload) {
    commit(UPDATE_FILE_SELECTED, payload)
  },
  updateModels({ commit }, payload) {
    commit(UPDATE_MODELS, payload)
  },
  updateNewProject({ commit }, payload) {
    commit(UPDATE_NEW_PROPERTY, payload)
  },
  resetNewProject({ commit }) {
    commit(RESET_NEW_PROPERTY)
  },
  updateActiveStep({ commit }, payload) {
    commit(UPDATE_ACTIVE_STEP, payload)
  },
  logout({ commit }) {
    commit(RESET_NEW_PROPERTY)
    commit(LOG_OUT)
    // commit(SET_LAYOUT, 'SimpleLayout')
    router.push({ name: 'Login' })
  },
  toggleModal({ commit }, payload) {
    commit(TOGGLE_MODAL, payload)
  },
  toggleOnboardingProgress({ commit }, payload) {
    commit(TOGGLE_ONBOARDING_PROGRESS, payload)
  },
  addBankInfo({ commit }, payload) {
    commit(ADD_BANK_INFO, payload)
  },
  verifyBankInfo({ commit }, payload) {
    commit(VERIFY_BANK_INFO, payload)
  },
  updateVerticalsAndOfferings({ commit }, payload) {
    commit(UPDATE_VERTICALS_AND_OFFERINGS, payload)
  },
  updateOnboardingSetting({ commit }, payload) {
    commit(UPDATE_ONBOARDING_SETTING, payload)
  },
  updateTooltipStep({ commit }, payload) {
    commit(UPDATE_TOOLTIP_STEP, payload)
  },
  updateLayout({ commit }, payload) {
    commit(SET_LAYOUT, payload)
  },
  proposalSystems({ commit }, payload) {
    commit(UPDATE_PROPOSAL_SYSTEMS, payload)
  },
  currentProposalOrder({ commit }, payload) {
    commit(UPDATE_PROPOSAL_ORDER, payload)
  },
  updateProposal({ commit }, payload) {
    commit(UPDATE_PROPOSAL, payload)
  },
}

const plugins = [
  createPersistedState({
    key: STORAGE_KEY,
    paths: ['user', 'token', 'layout'],
  }),
]

const getters = {
  isLoggedIn: state => {
    return state.token && state.user
  },
  user: state => {
    return state.user
  },
  markups: state => {
    return state.markups
  },
  files: state => {
    return state.files
  },
  fileSelected: state => {
    return state.fileSelected
  },
  models3D: state => {
    return state.models3D
  },
  token: state => {
    return state.token
  },
  isAdmin: state => {
    return state.user ? state.user.is_admin : false
  },
  isVendor: state => {
    return state.user ? state.user.is_vendor : false
  },
  isIntegrator: state => {
    return state.user ? state.user.is_integrator : false
  },
  isDeveloper: state => {
    return state.user ? state.user.is_developer : false
  },
  activeStep: state => {
    return state.activeStep
  },
  newProject: state => {
    return state.newProject
  },
  isApproved: state => {
    if (state.user) {
      if (!state.user.is_approved) return false
      if (state.user.company_ref) {
        return state.user.company_ref.is_approved
      }
      if (state.user.integrator_ref) {
        return state.user.integrator_ref.is_approved
      }
      if (state.user.vendor_ref) {
        return state.user.vendor_ref.is_approved
      }
    }
    return false
  },
  modal: state => {
    return state.modal
  },
  stripeBankVerified: state => {
    return state.user.company_ref && state.user.company_ref.stripeBankVerified
  },
  userHasPermission: state => permission => {
    if (!state.user || !state.user.is_approved) return false
    return state.user.permissions.some(p => p === permission)
  },
  verticalsAndOfferings: state => {
    return state.verticalsAndOfferings
  },
  onboardingProgress: state => {
    return state.onboardingProgress
  },
  onboardingSetting: state => state.onboardingSetting,
  tooltipStep: state => state.tooltipStep,
  layout: state => {
    return state.layout
  },
  proposalSystems: state => {
    return state.proposalSystems
  },
  currentProposalOrder: state => {
    return state.currentProposalOrder
  },
  proposal: state => {
    return state.proposal
  },
}

// todo: pass router as argument to expose current route. https://github.com/vuejs/vue-hackernews-2.0/commit/758961e73ac4089890079d4ce14996741cf9344b#r50475667
export default createStore({
  state,
  mutations,
  actions,
  getters,
  plugins,
})
