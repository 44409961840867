import Model, { fields } from '@thinknimble/tn-models'

import IntegratorAPI from './api'

export default class Integrator extends Model {
  static api = IntegratorAPI.create(Integrator)

  static id = new fields.IdField({ readOnly: true })
  static name = new fields.CharField()
  static logo = new fields.CharField()
  static url = new fields.CharField()
  static isApproved = new fields.BooleanField()
  static stripeAccountId = new fields.CharField()
  static systemCost = new fields.ArrayField({ type: new fields.IdField({ readOnly: true }) })
  static datetimeCreated = new fields.CharField()
  static lastUpdated = new fields.CharField()
  static licensedStates = new fields.ArrayField({
    type: new fields.IdField({ readOnly: true }),
  })
}
