const CREATE_PROPERTY = 'CREATE_PROPERTY'
const DISCOVER_PROPTECH = 'DISCOVER_PROPTECH'
const REQUEST_QUOTE = 'REQUEST_QUOTE'

const ONBOARDING_STEPS = [CREATE_PROPERTY, DISCOVER_PROPTECH, REQUEST_QUOTE]

export default {
  CREATE_PROPERTY,
  DISCOVER_PROPTECH,
  REQUEST_QUOTE,
  ONBOARDING_STEPS,
}
