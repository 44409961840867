import Model, { fields } from '@thinknimble/tn-models'
import CompanyApi from './api'

export default class Company extends Model {
  static api = CompanyApi.create(Company)
  static id = new fields.IdField({ readOnly: true })
  static name = new fields.CharField()
  static logo = new fields.CharField()
  static url = new fields.CharField()
  static owner = new fields.IdField({ readOnly: false })
  static companyTypeDescription = new fields.CharField()
  static isApproved = new fields.BooleanField()
  static proposalCredits = new fields.IntegerField()
  static stripeCustomerId = new fields.CharField()
  static datetimeCreated = new fields.CharField()
  static lastUpdated = new fields.CharField()
}
