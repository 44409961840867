const SELECT = 'SELECT'
const MULTISELECT = 'MULTISELECT'
const RANGE = 'RANGE'
const SYSTEM = 'SYSTEM'

const SYSTEM_FILTER_OPTIONS = [SELECT, MULTISELECT, RANGE]

const ADD_SYSTEM = 'products.add_system'
const CHANGE_SYSTEM = 'products.change_system'
const VIEW_ALL_SYSTEMS = 'products.view_all_systems'

const UPLOAD_FILE_FIELD_DETAILS = {
  name: 'Name of the Component, this is directly shown to the user while shopping',
  is_directly_sold:
    'Enter TRUE or FALSE. TRUE if you sell your Component directly, and FALSE if it’s sold through resellers or dealer',
  vertical: 'Category associated to the upload',
  description: 'System description which is shown to the user while shopping',
  offerings: 'Offerings associated to the upload',
}

const EXCLUDE_KEYS = ['image_url', 'integrators', 'vendor', 'vendor_id']

const MAX_IMAGES_PER_SYSTEM = 5

export default {
  SELECT,
  MULTISELECT,
  RANGE,
  SYSTEM_FILTER_OPTIONS,
  ADD_SYSTEM,
  CHANGE_SYSTEM,
  VIEW_ALL_SYSTEMS,
  UPLOAD_FILE_FIELD_DETAILS,
  SYSTEM,
  EXCLUDE_KEYS,
  MAX_IMAGES_PER_SYSTEM,
}
