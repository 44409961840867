<template>
  <div class="nav__wrapper">
    <div class="nav__container">
      <div class="nav">
        <router-link :to="{ name: isAdmin ? 'DashboardAdmin' : 'Portfolio' }">
          <img class="nav__logo" src="@/assets/icons/rewyre-logo-light.svg" alt="Rewyre" />
        </router-link>
        <div class="nav__buttons">
          <div v-for="button in buttons" :key="button.name">
            <template
              v-if="!button.requiredPermission || userHasPermission(button.requiredPermission)"
            >
              <router-link
                v-if="button"
                :id="`nav-${button.link}`"
                :to="{ name: button.link }"
                class="button nav__link icon-button"
                :class="{ 'nav__active-link': isActive(button.link) }"
              >
                <img
                  v-if="button.icon"
                  :src="require('@/assets/icons/' + button.icon)"
                  :alt="button.name"
                  class="product-icon"
                />
                {{ button.name }}
              </router-link>
            </template>
          </div>
          <div class="nav__dropdown nav__dropdown--links" @click.stop="toggleMenu">
            <button>
              <img class="quotes__menu" src="@/assets/icons/ellipse.svg" alt="Menu" />
            </button>
            <ul id="profile-dropdown2" class="nav__dropdown__list" v-show="openMenu" tabindex="-1">
              <li v-for="button in buttons" :key="button.name">
                <template
                  v-if="!button.requiredPermission || userHasPermission(button.requiredPermission)"
                >
                  <router-link
                    v-if="button"
                    :to="{ name: button.link }"
                    class="button nav__link--dropdown"
                    :class="{ 'nav__active-link': isActive(button.link) }"
                  >
                    <img
                      v-if="button.icon"
                      :src="require('@/assets/icons/' + button.icon)"
                      :alt="button.name"
                      class="product-icon"
                    />
                    {{ button.name }}
                  </router-link>
                </template>
              </li>
            </ul>
          </div>
          <div class="nav__dropdown" @click.stop="toggleDropdown">
            <button id="nav-profile" class="nav__profile" aria-label="Profile">
              <i
                v-if="proposalCredits > 0"
                v-badge="proposalCredits"
                class="pi pi-user p-overlay-badge avatar"
                style="color: white; font-size: 1.75rem"
              />
              <i
                v-else
                class="pi pi-user p-overlay-badge avatar"
                style="color: white; font-size: 1.75rem"
              />
            </button>
            <ul
              id="profile-dropdown"
              class="nav__dropdown__list"
              v-show="openDropdown"
              role="listbox"
              tabindex="-1"
            >
              <li class="nav__dropdown__option caption__light" role="option">
                <Button
                  id="credits-button"
                  classes="nav__dropdown__button"
                  :btnText="`Credits : ${proposalCredits}`"
                />
              </li>
              <li
                class="nav__dropdown__option caption__light"
                role="option"
                v-if="!isAdmin && isApproved && userHasPermission(userOptions.ACCESS_SETTINGS)"
              >
                <router-link :to="{ path: '/settings' }" class="nav__settings" tabindex="-1">
                  <Button id="settings-button" classes="nav__dropdown__button" btnText="Settings" />
                </router-link>
              </li>
              <li class="nav__dropdown__option caption__light" role="option">
                <router-link :to="{ path: '/faqs' }" class="nav__settings" tabindex="-1">
                  <Button id="faqs-button" classes="nav__dropdown__button" btnText="FAQs" />
                </router-link>
              </li>
              <li class="nav__dropdown__option caption__light" role="option">
                <router-link :to="{ path: '/contact' }" class="nav__settings" tabindex="-1">
                  <Button
                    id="contact-button"
                    classes="nav__dropdown__button"
                    btnText="Contact Support"
                  />
                </router-link>
              </li>
              <li
                v-if="isDeveloper && isApproved"
                class="nav__dropdown__option caption__light"
                @click.stop="openOnboardingProgress()"
              >
                <Button classes="nav__dropdown__button" btnText="Onboarding Tips" />
              </li>
              <li
                class="nav__dropdown__option nav__logout caption__light"
                role="option"
                @click="logout"
                @keydown="focusOutCloseDropdown"
              >
                <Button id="logout-submit" classes="nav__dropdown__button" btnText="Log Out" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <OnboardingProgress v-if="isDeveloper && isApproved && onboardingProgress" />
    </div>
  </div>
</template>

<script>
import { UserOptions } from '@/services/users'
import { mapGetters } from 'vuex'
import Button from '@/components/utils/Button'
import OnboardingProgress from '@/components/onboarding/OnboardingProgress'

// buttons props from links.js
// name and links -- i.e. nav-MyProducts
export default {
  props: {
    buttons: {
      type: Array,
      required: false,
    },
  },
  components: {
    OnboardingProgress,
    Button,
  },
  mounted() {
    this.proposalCredits =
      this.user?.is_developer || this.user?.isDeveloper
        ? this.user.company_ref.proposal_credits
        : this.user?.is_vendor || this.user?.isVendor
        ? this.user.vendor_ref.proposal_credits
        : 0
  },
  data() {
    return {
      userOptions: UserOptions,
      openDropdown: false,
      openMenu: false,
      proposalCredits: 0,
    }
  },
  methods: {
    toggleMenu() {
      this.openMenu = !this.openMenu
    },
    logout() {
      this.$store.dispatch('logout')
    },
    toggleDropdown() {
      this.openDropdown = !this.openDropdown
      this.$store.dispatch('toggleOnboardingProgress', false)
    },
    focusOutCloseDropdown(event) {
      if (event.keyCode === 9 && !event.shiftKey) {
        this.openDropdown = false
      }
    },
    isActive(link) {
      return this.$route.name === link
    },
    openOnboardingProgress() {
      this.$store.dispatch('toggleOnboardingProgress', true)
      this.openDropdown = !this.openDropdown
    },
  },
  computed: {
    ...mapGetters([
      'user',
      'isAdmin',
      'isVendor',
      'isDeveloper',
      'isApproved',
      'userHasPermission',
      'onboardingProgress',
      'proposal',
    ]),
    isDashboard() {
      return this.$route.name === 'Dashboard'
    },
  },
}
</script>

<style lang="scss" scoped>
.nav {
  @include fluid-property('padding-left', 16, 32);
  @include fluid-property('padding-right', 16, 32);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 2px $light;
  height: 3.75rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), $text-primary;

  &__wrapper {
    padding-bottom: 60px;
  }

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $nav;
    display: flex;
    flex-direction: column;
  }

  &__logo {
    @include fluid-property('width', 120, 160);
    vertical-align: middle;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__link {
    border: none;
    background-color: inherit;
    color: $headinggrey;

    &--dropdown {
      color: $text-primary;
      border: none;
    }
  }

  &__link:hover {
    color: $white;
  }

  &__active-link {
    color: $white;
    text-decoration: underline;
    font-weight: bold;
  }

  &__profile {
    background: none;
    border: 0;
    padding: 0;
    margin-left: 1rem;

    &__img {
      height: 100%;
      width: 2rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__settings {
    text-decoration: none;
    outline: none;
    color: black;
  }

  &__logout {
    cursor: pointer;
  }

  &__dropdown {
    cursor: pointer;
    display: flex;
    position: relative;

    &__list {
      min-width: 7rem;
      height: 300px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)),
        $text-primary;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
      border-radius: 5px;

      display: flex;
      flex-flow: column nowrap;
      justify-content: space-evenly;
      align-content: center;
      margin-top: 0.35rem;
      padding: 0.35rem 0;
      position: absolute;
      top: 100%;
      right: -50%;

      list-style: none;
    }

    &__option {
      font-weight: 100;
      text-align: center;
      font-size: 1rem;

      &:hover {
        background-color: $grey;
        color: $white;
        font-weight: 800;
      }
    }

    &__button {
      all: unset;
      width: 100%;
      padding: 0.5rem 0rem;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        background: $accent1;
        color: $text-primary;
        font-weight: 800;
      }
    }

    &--links {
      display: flex;
      @include breakpoint($tablet) {
        display: none;
      }

      button {
        background-color: transparent;
        border: 0;
      }

      ul {
        width: 10rem;
      }
    }
  }

  .icon-button {
    display: none;
    @include breakpoint($tablet) {
      display: flex;
    }

    .product-icon {
      margin-right: 0.5rem;

      /* recenter icon due to padding adjustments for Avenir font */
      position: relative;
      top: -1px;
    }
  }
}
</style>
