import Model, { fields } from '@thinknimble/tn-models'

import UserOnboardingStepAPI from './api'

export class OnboardingStepDetail extends Model {
  static id = new fields.IdField({ readOnly: true })
  static code = new fields.CharField()
  static title = new fields.CharField()
  static copy = new fields.CharField()
  static order = new fields.IntegerField()
}

export class OnboardingStep extends Model {
  static id = new fields.IdField({ readOnly: true })
  static code = new fields.CharField()
  static copy = new fields.CharField()
  static order = new fields.IntegerField()
}

export default class UserOnboardingStep extends Model {
  static api = UserOnboardingStepAPI.create(UserOnboardingStep)
  static id = new fields.IdField({ readOnly: true })
  static onboardingStep = new fields.IdField({ readOnly: true })
  static onboardingStepRef = new fields.ModelField({ ModelClass: OnboardingStep, readOnly: true })
  static isCompleted = new fields.BooleanField()
}
