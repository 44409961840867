import { apiErrorHandler } from '@/services/api'
import { ModelAPI } from '@thinknimble/tn-models'
import { apiClient } from '@/services/client'

export default class UserOnboardingStepAPI extends ModelAPI {
  static ENDPOINT = '/user-onboarding-steps/'

  /**
   * ModelAPI contains methods for list and create (overridden here) and the FILTERS_MAP
   * You may override any of these methods by statically defining them here
   * e.g static FILTERS_MAP={...UserAPI.FILTERS_MAP, <FILTERS>}
   *      list({ filters = {}, pagination = {} }){
   *
   * }
   */

  get client() {
    return apiClient()
  }

  static FILTERS_MAP = {
    ...UserOnboardingStepAPI.FILTERS_MAP,
    // Custom filters
  }

  async patch(userId, fields = [], excludeFields = []) {
    const url = `${UserOnboardingStepAPI.ENDPOINT}${userId}/`
    let data = this.cls.toAPI(fields, excludeFields)
    return this.client
      .patch(url, data)
      .then(response => response.data)
      .then(this.fromAPI)
      .catch(apiErrorHandler({ apiName: 'Patch User API error', rethrowErrors: true }))
  }
}
