import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { apiClient } from '@/services/client'
import { apiErrorHandler } from '@/services/api'

export default class IntegratorAPI extends ModelAPI {
  static ENDPOINT = '/integrators/'
  /**
   * ModelAPI contains methods for list and create (overridden here) and the FILTERS_MAP
   * You may override any of these methods by statically defining them here
   * e.g static FILTERS_MAP={...UserAPI.FILTERS_MAP, <FILTERS>}
   *      list({ filters = {}, pagination = {} }){
   *
   * }
   */

  static FILTERS_MAP = {
    ...IntegratorAPI.FILTERS_MAP,
    systems: ApiFilter.create({ key: 'systems' }),
    licensedStates: ApiFilter.create({ key: 'licensed_states' }),
  }

  get client() {
    return apiClient()
  }

  update(integratorId, fields = {}) {
    const url = `${IntegratorAPI.ENDPOINT}${integratorId}/`
    return this.client
      .patch(url, fields)
      .then(response => response.data)
      .then(this.fromAPI)
      .catch(
        apiErrorHandler({
          apiName: 'Update Integrator API error',
          rethrowErrors: true,
        }),
      )
  }
}
