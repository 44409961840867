import Model, { fields } from '@thinknimble/tn-models'
import Integrator from '../integrators'
import Offering from '../offerings'
import { SystemAttribute } from '../offerings/models'
import Product from '../products/models'
import Vendor from '../vendors'

import SystemAPI from './api'

export class SystemLineItem extends Model {
  static id = new fields.IdField({ readOnly: true })
  static product = new fields.IdField({})
  static productRef = new fields.ModelField({ ModelClass: Product, readOnly: true })
  static datetimeCreated = new fields.CharField()
  static lastUpdated = new fields.CharField()
}

export class CompatibleSystem extends Model {
  static id = new fields.IdField({ readOnly: true })
  static name = new fields.CharField()
  static displayImage = new fields.CharField()
  static datetimeCreated = new fields.CharField()
  static lastUpdated = new fields.CharField()
}

export class SystemImage extends Model {
  static id = new fields.IdField({ readOnly: true })
  static image = new fields.CharField()
  static compressedImage = new fields.CharField()
  static datetimeCreated = new fields.CharField()
  static lastUpdated = new fields.CharField()
}

export default class System extends Model {
  static api = SystemAPI.create(System)

  static id = new fields.IdField({ readOnly: true })
  static name = new fields.CharField()
  static images = new fields.ArrayField({ type: new fields.IdField({ readOnly: true }) })
  static imagesRefs = new fields.ArrayField({
    type: new fields.ModelField({ ModelClass: SystemImage, readOnly: true }),
  })
  static description = new fields.CharField()
  static vendor = new fields.IdField({})
  static vendorRef = new fields.ModelField({ ModelClass: Vendor, readOnly: true })
  static isDirectlySold = new fields.BooleanField()
  static integrators = new fields.ArrayField({ type: new fields.IdField({ readOnly: true }) })
  static integratorRefs = new fields.ArrayField({
    type: new fields.ModelField({ ModelClass: Integrator, readOnly: true }),
  })
  static offerings = new fields.IdField({})
  static offeringRefs = new fields.ArrayField({
    type: new fields.ModelField({ ModelClass: Offering, readOnly: true }),
  })
  static lineItemRefs = new fields.ArrayField({
    type: new fields.ModelField({ ModelClass: SystemLineItem }),
  })
  static upfrontCost = new fields.IntegerField()
  static monthlyCost = new fields.IntegerField()
  static isApproved = new fields.BooleanField()

  static systemUrl = new fields.CharField()
  static caseStudyPdf = new fields.CharField()
  static contactTitle = new fields.CharField()
  static contactName = new fields.CharField()
  static contactPhoneNumber = new fields.CharField()
  static rfqRequirements = new fields.CharField()
  static compatibleSystems = new fields.ArrayField({ type: new fields.IdField({ readOnly: true }) })
  static compatibleSystemsRefs = new fields.ArrayField({
    type: new fields.ModelField({ ModelClass: CompatibleSystem, readOnly: true }),
  })
  static attributes = new fields.ArrayField({ type: new fields.IdField({ readOnly: true }) })
  static attributeRefs = new fields.ArrayField({
    type: new fields.ModelField({ ModelClass: SystemAttribute, readOnly: true }),
  })
  static isUserFavorite = new fields.BooleanField()
  static clean(value) {
    return value
  }
}
