import Model, { fields } from '@thinknimble/tn-models'

import OfferingAPI from './api'
import Feature from '../features'

export class SystemFieldLite extends Model {
  static id = new fields.IdField({ readOnly: true })
  static name = new fields.CharField()
  static description = new fields.CharField()
}

export class SystemAttribute extends Model {
  static id = new fields.IdField({ readOnly: true })
  static value = new fields.CharField()
  static fieldRef = new fields.ModelField({ ModelClass: SystemFieldLite, readOnly: true })
}

export class SystemField extends Model {
  static id = new fields.IdField({ readOnly: true })
  static name = new fields.CharField()
  static description = new fields.CharField()
  static filterType = new fields.CharField()
  static systemAttributes = new fields.ArrayField({
    type: new fields.IdField({ readOnly: true }),
  })
  static systemAttributeRefs = new fields.ArrayField({
    type: new fields.ModelField({ ModelClass: SystemAttribute, readOnly: true }),
  })
}

export default class Offering extends Model {
  static api = OfferingAPI.create(Offering)

  static id = new fields.IdField({ readOnly: true })
  static name = new fields.CharField()
  static description = new fields.CharField()
  static image = new fields.CharField()
  static icon = new fields.CharField()
  static features = new fields.ArrayField({
    type: new fields.IdField({ readOnly: true }),
  })
  static featureRefs = new fields.ArrayField({
    type: new fields.ModelField({ ModelClass: Feature, readOnly: true }),
  })
  static systemFields = new fields.ArrayField({
    type: new fields.IdField({ readOnly: true }),
  })
  static systemFieldRefs = new fields.ArrayField({
    type: new fields.ModelField({ ModelClass: SystemField, readOnly: true }),
  })
  static vertical = new fields.IdField({})
  static datetimeCreated = new fields.CharField()
  static lastUpdated = new fields.CharField()
  static clean(value) {
    return value
  }
}
