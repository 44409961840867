import Model, { fields } from '@thinknimble/tn-models'
import FeatureAPI from './api'

export default class Feature extends Model {
  static api = FeatureAPI.create(Feature)

  static id = new fields.IdField({ readOnly: true })
  static name = new fields.CharField()
  static description = new fields.CharField()
  static icon = new fields.CharField()
  static datetimeCreated = new fields.CharField()
  static lastUpdated = new fields.CharField()
}
