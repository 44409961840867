<template>
  <div>
    <NavBarContainer v-if="user" />
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueScrollTo from 'vue-scrollto'
import NavBarContainer from '@/components/utils/NavBarContainer'
export default {
  name: 'NavBarLayout',
  components: { NavBarContainer },
  computed: {
    ...mapGetters(['user']),
  },
  watch: {
    // When route changes, scroll to the top
    '$route.path': function watchRoutePath() {
      VueScrollTo.scrollTo('#app', 200)
    },
  },
}
</script>
