import Model, { fields } from '@thinknimble/tn-models'

import ProductApi from './api'
import Vendor from '@/services/vendors'
import UseType from '@/services/use-types'
import SoftwareHardware from '@/services/software-hardwares'
import IntegratorProduct from '@/services/integrator-products'

export class Voltage extends Model {
  static name = new fields.CharField()
}

export default class Product extends Model {
  static api = ProductApi.create(Product)
  static id = new fields.IdField({ readOnly: true })
  static name = new fields.CharField()
  static displayImage = new fields.CharField()
  static description = new fields.CharField()
  static vendor = new fields.IdField({ readOnly: false })
  static vendorRef = new fields.ModelField({ ModelClass: Vendor, readOnly: true })
  static isDirectlySold = new fields.BooleanField()
  static integratorProductsRefs = new fields.ArrayField({
    type: new fields.ModelField({ ModelClass: IntegratorProduct, readOnly: true }),
  })
  static productCategory = new fields.CharField()
  static oneTimeCharge = new fields.BooleanField()
  static recurringCharge = new fields.BooleanField()
  static perUnitCost = new fields.IntegerField()
  static monthlyCost = new fields.IntegerField()
  static useTypes = new fields.ArrayField({ type: new fields.IdField({ readOnly: true }) })
  static useTypesRef = new fields.ArrayField({
    type: new fields.ModelField({ ModelClass: UseType, readOnly: true }),
  })
  static softwareHardware = new fields.IdField({ readOnly: false })
  static softwareHardwareRef = new fields.ModelField({
    ModelClass: SoftwareHardware,
    readOnly: true,
  })
  static mobileApp = new fields.BooleanField()
  static batteryPowered = new fields.BooleanField()
  static openSystem = new fields.BooleanField()
  static clean(value) {
    return value
  }
}
