export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_TOKEN = 'UPDATE_USER_TOKEN'
export const UPDATE_USER_ORGANIZATION_OWNER = 'UPDATE_USER_ORGANIZATION_OWNER'
export const UPDATE_NEW_PROPERTY = 'UPDATE_NEW_PROPERTY'
export const RESET_NEW_PROPERTY = 'RESET_NEW_PROPERTY'
export const UPDATE_ACTIVE_STEP = 'UPDATE_ACTIVE_STEP'
export const LOG_OUT = 'LOG_OUT'
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const UPDATE_MODELS = 'UPDATE_MODELS'
export const UPDATE_FILES = 'UPDATE_FILES'
export const UPDATE_MARKUPS = 'UPDATE_MARKUPS'
export const UPDATE_FILE_SELECTED = 'UPDATE_FILE_SELECTED'
export const ADD_BANK_INFO = 'ADD_BANK_INFO'
export const VERIFY_BANK_INFO = 'VERIFY_BANK_INFO'
export const UPDATE_VERTICALS_AND_OFFERINGS = 'UPDATE_VERTICALS_AND_OFFERINGS'
export const TOGGLE_ONBOARDING_PROGRESS = 'TOGGLE_ONBOARDING_PROGRESS'
export const UPDATE_ONBOARDING_SETTING = 'UPDATE_ONBOARDING_SETTING'
export const UPDATE_TOOLTIP_STEP = 'UPDATE_TOOLTIP_STEP'
export const SET_LAYOUT = 'SET_LAYOUT'
export const UPDATE_PROPOSAL_SYSTEMS = 'UPDATE_PROPOSAL_SYSTEMS'
export const UPDATE_PROPOSAL_ORDER = 'UPDATE_PROPOSAL_ORDER'
export const UPDATE_PROPOSAL = 'UPDATE_PROPOSAL'
