const PENDING = 'pending'
const IN_PROGRESS = 'inprogress'
const SUCCESS = 'success'
const FAILED = 'failed'
const TIMEOUT = 'timeout'

const URN_STATUSES = [PENDING, IN_PROGRESS, SUCCESS, FAILED, TIMEOUT]

const URN_FAILED_STATUSES = [FAILED, TIMEOUT]
const URN_PENDING_STATUSES = [PENDING, IN_PROGRESS]

const URN_STATUS_CHOICES = [
  { value: PENDING, label: 'Pending' },
  { value: IN_PROGRESS, label: 'In Progress' },
  { value: SUCCESS, label: 'Success' },
  { value: FAILED, label: 'Failed' },
  { value: TIMEOUT, label: 'Timeout' },
]

const URN_STATUS_MESSAGES = [
  {
    value: PENDING,
    label: 'Your file is currently uploading. Please check back again later.',
  },
  {
    value: IN_PROGRESS,
    label: 'Your file is currently uploading. Please check back again later.',
  },
  { value: FAILED, label: 'Your file failed to upload.' },
  { value: TIMEOUT, label: 'Your file failed to upload.' },
]

const SQ_FT = 'sq ft'
const SQ_M = 'sq m'

const PROJECT_SIZES = [SQ_FT, SQ_M]

const PROJECT_SIZE_CHOICES = [
  { name: SQ_FT, id: 'sq ft' },
  { name: SQ_M, id: 'sq m' },
]

const USD = 'USD'
const CAD = 'CAD'
const EUR = 'EUR'
const JPY = 'JPY'
const GBP = 'GBP'
const AUD = 'AUD'
const CHF = 'CHF'
const CNH = 'CNH'

const CURRENCY = [USD, CAD, EUR, JPY, GBP, AUD, CHF, CNH]

const CURRENCY_CHOICES = [
  {
    name: 'USD',
    id: USD,
    symbol: '$',
  },
  {
    name: 'CAD',
    id: CAD,
    symbol: '$',
  },
  {
    name: 'EUR',
    id: EUR,
    symbol: '€',
  },
  {
    name: 'JPY',
    id: JPY,
    symbol: '¥',
  },
  {
    name: 'AUD',
    id: AUD,
    symbol: '$',
  },
  {
    name: 'CHF',
    id: CHF,
    symbol: 'CHF',
  },
  {
    name: 'CNH',
    id: CNH,
    symbol: '¥',
  },
]

const ACTIVE = 'ACTIVE'
const ARCHIVED = 'ARCHIVED'

const STATUSES = [ACTIVE, ARCHIVED]
const STATUS_CHOICES = [
  {
    name: 'Active',
    id: ACTIVE,
    color: '#049dbf',
  },
  {
    name: 'Inactive',
    id: ARCHIVED,
    color: '#ff9e61',
  },
]

const PUBLIC = 'PUBLIC'
const PRIVATE = 'PRIVATE'

const PRIVACY_CHOICES = [
  {
    name: 'Active',
    id: PUBLIC,
    color: '#049dbf',
  },
  {
    name: 'Inactive',
    id: PRIVATE,
    color: '#ff9e61',
  },
]

export default {
  PENDING,
  IN_PROGRESS,
  SUCCESS,
  FAILED,
  TIMEOUT,
  URN_FAILED_STATUSES,
  URN_PENDING_STATUSES,
  URN_STATUS_MESSAGES,
  URN_STATUSES,
  URN_STATUS_CHOICES,
  PROJECT_SIZES,
  PROJECT_SIZE_CHOICES,
  CURRENCY,
  CURRENCY_CHOICES,
  ACTIVE,
  ARCHIVED,
  STATUSES,
  STATUS_CHOICES,
  PUBLIC,
  PRIVATE,
  PRIVACY_CHOICES,
}
