import Model, { fields } from '@thinknimble/tn-models'
import SoftwareHardwareAPI from './api'

export default class SoftwareHardware extends Model {
  static api = SoftwareHardwareAPI.create(SoftwareHardware)

  static id = new fields.IdField({ readOnly: true })
  static name = new fields.CharField()
  static datetimeCreated = new fields.CharField()
  static lastUpdated = new fields.CharField()
}
