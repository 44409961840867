import Model, { fields } from '@thinknimble/tn-models'

import IntegratorProductAPI from './api'
import Integrator from '@/services/integrators'

export default class IntegratorProduct extends Model {
  static api = IntegratorProductAPI.create(IntegratorProduct)

  static id = new fields.IdField({ readOnly: true })
  static integrator = new fields.IdField()
  static product = new fields.IdField()
  static upfrontCost = new fields.IntegerField()
  static monthlyCost = new fields.IntegerField()
  static datetimeCreated = new fields.CharField()
  static lastUpdated = new fields.CharField()
  static integratorRef = new fields.ModelField({ ModelClass: Integrator, readOnly: true })
}
