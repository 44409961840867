<template>
  <div class="modal">
    <div class="modal__header">
      <h2 class="subheader__heavy heading--light">
        {{ onboardingComplete ? 'Onboarding Complete' : 'Onboarding Process' }}
      </h2>
      <div class="modal__header__buttons">
        <img
          @click="closeModal"
          class="modal__header__button"
          src="@/assets/icons/close-button-x.svg"
          alt="Close"
        />
        <img
          @click="expanded = !expanded"
          class="modal__header__button"
          :class="{
            modal__header__reverse: !expanded,
          }"
          src="@/assets/icons/arrow-white.svg"
          alt="Expand"
        />
      </div>
    </div>
    <div :class="{ modal__finished: onboardingComplete }">
      <div v-if="expanded || onboardingComplete" class="modal__icons">
        <div
          v-for="(step, index) in steps"
          :key="index"
          class="modal__icons__wrapper"
          :class="{
            modal__complete: completedStep(step),
            'modal__next-step': incompleteStepIndex == index,
          }"
        >
          <div @click="goToPage(step)" class="modal__icons__background">
            <img class="modal__icons__icon" :src="step.image" :alt="step.alt" />
            <div class="modal__icons__layer">
              <img
                class="modal__icons__check"
                src="@/assets/icons/check-mark-white.svg"
                alt="Check Mark"
              />
            </div>
          </div>
          <p v-if="!onboardingComplete" class="modal__icons__text caption__light">
            {{ step.text }}
          </p>
        </div>
      </div>
      <div class="modal__progress caption__light heading--light">
        <p>Progress</p>
        <div class="modal__progress__wrapper">
          <div class="modal__progress__bar">
            <div
              :style="{ width: (stepsCompleted / 3) * 100 + '%' }"
              class="modal__progress__complete"
            ></div>
          </div>
          {{ stepsCompleted }}/3
        </div>
      </div>
    </div>
    <div v-if="onboardingComplete && expanded" class="caption__light heading--light modal__footer">
      <p class="modal__footer__text">
        Well done! You have completed your onboarding with ReWyre. To see the quote you just
        requested, navigate to the 'Quotes' page above. You can also go to the corresponding
        property dashboard to see status updates as they come in.
      </p>
      <p>Enjoy using ReWyre!</p>
      <button class="modal__footer__button" @click="closeModal">Close Window</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { OnboardingSteps } from '@/services/user-onboarding-steps'
export default {
  data() {
    return {
      expanded: true,
      steps: [
        {
          link: 'Dashboard',
          image: require('@/assets/icons/skyline.svg'),
          alt: 'Skyline',
          text: 'Create your first property',
          code: OnboardingSteps.CREATE_PROPERTY,
        },
        {
          link: 'SystemSearch',
          image: require('@/assets/icons/magnifier.svg'),
          alt: 'Magnifier',
          text: 'Discover and compare proptech',
          code: OnboardingSteps.DISCOVER_PROPTECH,
        },
        {
          link: 'SystemSearch',
          image: require('@/assets/icons/money-transfer.svg'),
          alt: 'Money Transfer',
          text: 'Request your first quote',
          code: OnboardingSteps.REQUEST_QUOTE,
        },
      ],
      onboardedSteps: [],
    }
  },
  created() {
    this.onboardedSteps = [
      ...new Map(this.user.onboarding_steps_ref.map(item => [item.onboarding_step, item])).values(),
    ].sort((a, b) => a.onboarding_step_ref.order > b.onboarding_step_ref.order)
  },
  methods: {
    completedStep(step) {
      return this.onboardedSteps.find(
        onboardedStep => onboardedStep.onboarding_step_ref.code == step.code,
      ).is_completed
    },
    closeModal() {
      this.$store.dispatch('toggleOnboardingProgress', false)
    },
    goToPage(step) {
      if (!this.allStepsCompleted) {
        this.$router.push({ name: step.link })
      }
    },
  },
  computed: {
    ...mapGetters(['user']),
    allStepsCompleted() {
      return this.onboardedSteps.every(step => step.is_completed)
    },
    stepsCompleted() {
      return this.onboardedSteps.filter(step => !!step.is_completed).length
    },
    onboardingComplete() {
      return this.onboardedSteps.filter(step => !step.is_completed).length == 0
    },
    incompleteStepIndex() {
      const incomplete = this.steps.findIndex(step =>
        this.onboardedSteps.find(
          onboarded => !onboarded.is_completed && onboarded.onboarding_step_ref.code == step.code,
        ),
      )
      return incomplete
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), $text-primary;
  width: fit-content;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  align-self: end;
  position: absolute;
  top: 3.5rem;
  right: 1.5rem;

  &__header {
    display: flex;
    justify-content: space-between;

    &__button {
      height: 0.5rem;
      margin-left: 0.75rem;
      cursor: pointer;
    }

    &__buttons {
      margin-left: 2rem;
      display: flex;
      align-items: center;
    }

    &__reverse {
      transform: rotate(180deg);
    }
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    &__wrapper {
      margin: 1rem 0;
      width: 5.75rem;

      &:nth-child(2) {
        margin: 1rem 1.5rem;
      }
    }

    &__background {
      background-color: $backgroundGrey;
      border-radius: 5px;
      border: 3px solid $backgroundGrey;
      height: 5.75rem;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    &__layer {
      display: none;
    }

    &__check {
      width: 3.25rem;
    }

    &__icon {
      width: 100%;
    }

    &__text {
      color: $textGrey;
      text-align: center;
      margin-top: 0.5rem;
    }
  }

  &__progress {
    width: 20.25rem;
    background-color: $backgroundGrey;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    margin-top: 0.25rem;
    &__bar {
      width: 18rem;
      height: 4px;
      background-color: $textGrey;
      border-radius: 5px;
      margin: 0.25rem 0.25rem 0.25rem 0;
    }

    &__complete {
      background-color: $accent2dark;
      height: 4px;
      border-radius: 5px;
    }

    &__wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;

    &__text {
      margin: 1rem 0;
      max-width: 26rem;
    }

    &__button {
      align-self: flex-end;
      color: $white;
      background: transparent;
      border: none;
      cursor: pointer;
      font-weight: bold;
      margin-top: 1rem;
    }
  }

  &__next-step {
    .modal__icons__background {
      border: 3px solid $accent2dark;
    }
  }

  &__complete {
    .modal__icons__text {
      color: $white;
    }

    .modal__icons__background {
      position: relative;
      cursor: auto;
    }

    .modal__icons__layer {
      background-color: $navyBlueTransparent;
      width: 5.75rem;
      border-radius: 5px;
      height: 5.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -3px;
      left: -3px;
    }
  }

  &__finished {
    display: flex;
    margin-top: 1rem;

    .modal {
      &__icons {
        &__wrapper {
          margin: 0;
          width: 3rem;

          &:nth-child(2) {
            margin: 0 0.75rem;
          }
        }

        &__background {
          height: 3rem;
        }

        &__check {
          width: 1.5rem;
        }

        &__layer {
          width: 3rem;
          height: 3rem;
        }
      }

      &__progress {
        width: 14.8rem;
        // padding: 0.5rem 1rem 0 1rem;
        border-radius: 5px;
        margin-left: 0.75rem;
        margin-top: 0;

        &__bar {
          width: 13.6rem;
          height: 4px;
          margin: 0.25rem 0.25rem 0.25rem 0;
        }
      }
    }
  }
}
</style>
