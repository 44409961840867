import { apiErrorHandler } from '@/services/api'
import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { apiClient } from '@/services/client'

// API Endpoints
const LOGIN_ENDPOINT = '/login/'
const PASSWORD_RESET_REQUEST_ENDPOINT = '/password/reset/'
const PASSWORD_RESET_CONFIRM_ENDPOINT = '/password/reset/confirm/'
const CONTACT_SUPPORT_ENDPOINT = '/core/support-email/'

export default class UserAPI extends ModelAPI {
  static ENDPOINT = '/users/'

  /**
   * ModelAPI contains methods for list and create (overridden here) and the FILTERS_MAP
   * You may override any of these methods by statically defining them here
   * e.g static FILTERS_MAP={...UserAPI.FILTERS_MAP, <FILTERS>}
   *      list({ filters = {}, pagination = {} }){
   *
   * }
   */

  get client() {
    return apiClient()
  }

  static FILTERS_MAP = {
    ...UserAPI.FILTERS_MAP,
    // Custom filters
    companyName: ApiFilter.create({ key: 'company_name' }),
    vendorName: ApiFilter.create({ key: 'vendor_name' }),
    integratorName: ApiFilter.create({ key: 'integrator_name' }),
  }

  login({ email, password }) {
    const data = { email: email.toLowerCase(), password: password }
    const promise = apiClient()
      .post(LOGIN_ENDPOINT, data)
      .then(response => response.data)
      .catch(
        apiErrorHandler({
          apiName: 'UserAPI.login',
          enable400Alert: false,
          enable500Alert: false,
        }),
      )
    return promise
  }

  companySignup(data) {
    return apiClient()
      .post(UserAPI.ENDPOINT, data)
      .then(response => response)
      .catch(error => error.response)
  }

  async passwordResetRequest(email) {
    const data = { email: email.toLowerCase() }
    return await apiClient()
      .post(PASSWORD_RESET_REQUEST_ENDPOINT, data)
      .catch(
        apiErrorHandler({
          apiName: 'UserAPI.passwordResetRequest',
          enable400Alert: false,
          enable500Alert: false,
        }),
      )
  }

  async confirmNewPassword(newPassword1, newPassword2, uid, token) {
    const data = {
      new_password1: newPassword1,
      new_password2: newPassword2,
      uid,
      token,
    }
    return await apiClient()
      .post(PASSWORD_RESET_CONFIRM_ENDPOINT, data)
      .catch(
        apiErrorHandler({
          apiName: 'UserAPI.confirmNewPassword',
          enable400Alert: false,
          enable500Alert: false,
        }),
      )
  }

  async delete(userId) {
    const url = `${UserAPI.ENDPOINT}${userId}/`
    await this.client
      .delete(url)
      .catch(apiErrorHandler({ apiName: 'Delete User API error', rethrowErrors: true }))
  }

  async patch(userId, fields = [], excludeFields = []) {
    const url = `${UserAPI.ENDPOINT}${userId}/`
    let data = this.cls.toAPI(fields, excludeFields)
    return this.client
      .patch(url, data)
      .then(response => response.data)
      .then(this.fromAPI)
      .catch(apiErrorHandler({ apiName: 'Patch User API error', rethrowErrors: true }))
  }

  async sendSupportEmail(form) {
    let data = this.cls.toAPI(form, [])
    return this.client
      .post(CONTACT_SUPPORT_ENDPOINT, data)
      .then(response => response.data)
      .then(this.fromAPI)
      .catch(apiErrorHandler({ apiName: 'Send Contact Email API error', rethrowErrors: true }))
  }
}
