import { createRouter, createWebHistory } from 'vue-router'
import {
  requireAuth,
  requireAuthAdminOnly,
  requireDevArchUserOnly,
  requireApprovedDevArchUserOnly,
  requireAuthUserOnly,
  requireAuthApprovedUserOnly,
  requireAuthApprovedUserOrAdminUser,
  requireAuthVendorUserOnly,
  noAuth,
} from '@/services/auth'
import UserOptions from '@/services/users/options'
import { SystemOptions } from '@/services/systems'

const Login = () => import(/* webpackChunkName: 'group-auth' */ '@/views/auth/Login.vue')
const Signup = () => import(/* webpackChunkName: 'group-auth' */ '@/views/auth/Signup.vue')
const CompanySignup = () =>
  import(/* webpackChunkName: 'group-auth' */ '@/views/auth/CompanySignup.vue')
const ProviderSignup = () =>
  import(/* webpackChunkName: 'group-auth' */ '@/views/auth/ProviderSignup.vue')
const ResetPassword = () =>
  import(/* webpackChunkName: 'group-auth' */ '@/views/auth/ResetPassword.vue')
const ConfirmPassword = () =>
  import(/* webpackChunkName: 'group-auth' */ '@/views/auth/ConfirmPassword.vue')
const Welcome = () => import('@/views/misc/Welcome.vue')
const Settings = () => import('@/views/settings/Settings.vue')
const TermsOfService = () =>
  import(/* webpackChunkName: 'group-legal' */ '@/views/legal/TermsOfService.vue')
const TermsAndConditionsPartner = () =>
  import(/* webpackChunkName: 'group-legal' */ '@/views/legal/TermsAndConditionsPartner.vue')
const TermsAndConditionsClient = () =>
  import(/* webpackChunkName: 'group-legal' */ '@/views/legal/TermsAndConditionsClient.vue')
const PrivacyPolicy = () =>
  import(/* webpackChunkName: 'group-legal' */ '@/views/legal/PrivacyPolicy.vue')
const NewProject = () => import('@/views/project/NewProject.vue')
const SystemSearch = () =>
  import(
    /* webpackChunkName: 'group-products' */ '@/views/product-system/system-search/SystemSearch.vue'
  )
const VendorProducts = () =>
  import(
    /* webpackChunkName: 'group-vendors' */ '@/views/product-system/system-search/VendorProducts.vue'
  )
const VendorBulkUpload = () =>
  import(/* webpackChunkName: 'group-vendors' */ '@/views/product-system/VendorBulkUpload.vue')
const SystemComparison = () =>
  import(/* webpackChunkName: 'group-products' */ '@/views/product-system/SystemComparison.vue')
const PropertyDetail = () =>
  import(/* webpackChunkName: 'group-project' */ '@/views/project/PropertyDetail.vue')
const FAQs = () => import(/* webpackChunkName: 'group-faqs' */ '@/views/misc/FAQs.vue')
const Contact = () => import(/* webpackChunkName: 'group-contact' */ '@/views/misc/Contact.vue')
const DashboardAdmin = () => import('@/views/dashboards/DashboardAdmin.vue')
const AdminQuotes = () =>
  import(/* webpackChunkName: 'group-quotes' */ '@/views/quotes/AdminQuotes.vue')
const DeveloperQuotes = () => import('@/views/quotes/DeveloperQuotes.vue')
const SystemDetail = () => import('@/views/product-system/SystemDetail.vue')
const Portfolio = () =>
  import(/* webpackChunkName: 'group-project' */ '@/views/projects/Portfolio.vue')
const ProjectDetails = () =>
  import(/* webpackChunkName: 'group-project' */ '@/views/projects/ProjectDetails.vue')
const NewRFP = () => import(/* webpackChunkName: 'group-project' */ '@/views/proposal/NewRFP.vue')
const ContactInformation = () =>
  import(/* webpackChunkName: 'group-project' */ '@/views/proposal/ContactInformation.vue')
const SubmitRequest = () =>
  import(/* webpackChunkName: 'group-project' */ '@/views/proposal/SubmitRequest.vue')
const Success = () => import(/* webpackChunkName: 'group-project' */ '@/views/proposal/Success.vue')

const NewProposal = () =>
  import(/* webpackChunkName: 'group-proposals' */ '@/views/proposal/NewProposal.vue')
const ProposalPreview = () =>
  import(/* webpackChunkName: 'group-proposals' */ '@/views/proposal/ProposalPreview.vue')
const AddSystem = () =>
  import(/* webpackChunkName: 'group-products' */ '@/views/product-system/AddSystem.vue')
const AddComponent = () =>
  import(/* webpackChunkName: 'group-products' */ '@/views/product-system/AddComponent.vue')
const VendorDashboard = () =>
  import(/* webpackChunkName: 'group-vendors' */ '@/views/dashboards/VendorDashboard.vue')
const IntegratorDashboard = () =>
  import(/* webpackChunkName: 'group-integrators' */ '@/views/dashboards/IntegratorDashboard.vue')
const Dashboard = () =>
  import(/* webpackChunkName: 'group-integrators' */ '@/views/dashboards/Dashboard.vue')
const router = createRouter({
  // This mode removes trailing /#/ sign from URLs
  history: createWebHistory(),

  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login,
    },
    {
      path: '/signup/:magic_string/',
      name: 'Signup',
      beforeEnter: noAuth,
      component: Signup,
    },
    {
      path: '/signup',
      name: 'CompanySignup',
      beforeEnter: noAuth,
      component: CompanySignup,
    },
    {
      path: '/provider-signup',
      name: 'ProviderSignup',
      beforeEnter: noAuth,
      component: ProviderSignup,
    },
    {
      path: '/password/reset/',
      name: 'ResetPassword',
      beforeEnter: noAuth,
      component: ResetPassword,
    },
    {
      path: '/password/reset/confirm/:uid/:token/',
      name: 'ConfirmPassword',
      beforeEnter: noAuth,
      component: ConfirmPassword,
    },
    {
      path: '/settings',
      name: 'Settings',
      beforeEnter: requireAuthApprovedUserOnly,
      component: Settings,
      meta: {
        requiredPermission: UserOptions.ACCESS_SETTINGS,
      },
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfService',
      component: TermsOfService,
    },
    {
      path: '/terms-and-conditions-channel-partner',
      name: 'TermsAndConditionsPartner',
      component: TermsAndConditionsPartner,
    },
    {
      path: '/terms-and-conditions-client',
      name: 'TermsAndConditionsClient',
      component: TermsAndConditionsClient,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
    },
    {
      path: '/property/:projectId',
      name: 'PropertyDetail',
      component: PropertyDetail,
      beforeEnter: requireAuthApprovedUserOrAdminUser,
    },
    {
      path: '/welcome',
      name: 'Welcome',
      component: Welcome,
      beforeEnter: requireAuth,
    },
    {
      path: '/portfolio',
      name: 'Portfolio',
      component: Portfolio,
      beforeEnter: requireAuth,
    },
    {
      path: '/portfolio/:projectId',
      name: 'ProjectDetails',
      component: ProjectDetails,
      beforeEnter: requireAuth,
    },
    {
      path: '/portfolio/:projectId/new-rfp',
      name: 'NewRFP',
      component: NewRFP,
      beforeEnter: requireAuth,
    },
    {
      path: '/portfolio/:projectId/contact-info',
      name: 'ContactInformation',
      component: ContactInformation,
      beforeEnter: requireAuth,
    },
    {
      path: '/portfolio/:projectId/submit-request',
      name: 'SubmitRequest',
      component: SubmitRequest,
      beforeEnter: requireAuth,
    },
    {
      path: '/portfolio/:projectId/success',
      name: 'Success',
      component: Success,
      beforeEnter: requireAuth,
    },
    {
      path: '/portfolio/:projectId/proposals/:proposalId',
      name: 'NewProposal',
      component: NewProposal,
      beforeEnter: requireAuth,
    },
    {
      path: '/portfolio/:projectId/proposals/:proposalId/preview',
      name: 'ProposalPreview',
      component: ProposalPreview,
      beforeEnter: requireAuth,
    },
    // User only routes
    {
      path: '/new-project',
      name: 'NewProject',
      component: NewProject,
      beforeEnter: requireAuthApprovedUserOnly,
    },
    {
      path: '/preferences',
      name: 'MarketplacePreferences',
      component: () =>
        import(
          /* webpackChunkName: 'group-products' */ '@/views/product-system/MarketplacePreferences.vue'
        ),
      beforeEnter: requireApprovedDevArchUserOnly,
    },
    {
      path: '/products',
      name: 'SystemSearch',
      component: SystemSearch,
      beforeEnter: requireAuthUserOnly,
      meta: {
        requiredPermission: SystemOptions.VIEW_ALL_SYSTEMS,
      },
    },
    {
      path: '/portfolio/:projectId/proposals/:proposalId/products',
      name: 'ProposalProducts',
      component: SystemSearch,
      beforeEnter: requireAuthUserOnly,
      meta: {
        requiredPermission: SystemOptions.VIEW_ALL_SYSTEMS,
      },
    },
    {
      path: '/my-products',
      name: 'MyProducts',
      component: SystemSearch,
      beforeEnter: requireAuthApprovedUserOnly,
    },
    {
      path: '/vendor-products',
      name: 'VendorProducts',
      component: VendorProducts,
      beforeEnter: requireAuthVendorUserOnly,
    },
    {
      path: '/vendor-bulk-upload',
      name: 'VendorBulkUpload',
      component: VendorBulkUpload,
      beforeEnter: requireAuthVendorUserOnly,
    },
    {
      path: '/comparisons',
      name: 'SystemComparison',
      component: SystemComparison,
      beforeEnter: requireDevArchUserOnly,
    },
    {
      path: '/faqs',
      name: 'FAQs',
      component: FAQs,
      beforeEnter: requireAuthUserOnly,
    },
    {
      path: '/contact/:option?/:system?',
      name: 'Contact',
      component: Contact,
      beforeEnter: requireAuthUserOnly,
    },
    {
      path: '/system/:systemId',
      name: 'SystemDetail',
      component: SystemDetail,
      beforeEnter: requireAuthUserOnly,
    },
    // Admin only routes
    {
      path: '/rewyre-admin/dashboard',
      name: 'DashboardAdmin',
      beforeEnter: requireAuthAdminOnly,
      component: DashboardAdmin,
    },
    {
      path: '/rewyre-admin/quotes',
      name: 'AdminQuotes',
      beforeEnter: requireAuthAdminOnly,
      component: AdminQuotes,
    },
    {
      path: '/quotes',
      name: 'DeveloperQuotes',
      component: DeveloperQuotes,
    },
    {
      path: '/add-system',
      name: 'AddSystem',
      component: AddSystem,
    },
    {
      path: '/add-component',
      name: 'AddComponent',
      component: AddComponent,
    },
    {
      path: '/vendor-quotes',
      name: 'VendorDashboard',
      beforeEnter: requireAuthVendorUserOnly,
      component: VendorDashboard,
    },
    {
      path: '/integrator-quotes',
      name: 'IntegratorDashboard',
      component: IntegratorDashboard,
    },
    {
      path: '/portfolio',
      name: 'Dashboard',
      component: Dashboard,
    },
  ],
})

export default router
