import Model, { fields } from '@thinknimble/tn-models'
import UserOnboardingStep from '../user-onboarding-steps'
import Company from '@/services/companies/models'
import Integrator from '@/services/integrators/models'
import Vendor from '@/services/vendors/models'
import UserAPI from './api'

export default class User extends Model {
  static api = UserAPI.create(User)

  static id = new fields.CharField()
  static firstName = new fields.CharField()
  static lastName = new fields.CharField()
  static email = new fields.CharField()
  static fullName = new fields.CharField()
  static isAdmin = new fields.BooleanField()
  static isVendor = new fields.BooleanField()
  static isDeveloper = new fields.BooleanField()
  static isIntegrator = new fields.BooleanField()
  static vendor = new fields.IdField()
  static vendorRef = new fields.ModelField({ ModelClass: Vendor, readOnly: true })
  static integrator = new fields.IdField()
  static integratorRef = new fields.ModelField({ ModelClass: Integrator, readOnly: true })
  static companyRef = new fields.ModelField({ ModelClass: Company, readOnly: true })
  static isApproved = new fields.BooleanField()
  static termsOfService = new fields.BooleanField()
  static role = new fields.CharField()
  static teamRole = new fields.CharField()
  static phoneNumber = new fields.CharField()
  static proposalsEnabled = new fields.BooleanField()
  static permissions = new fields.ArrayField({
    type: new fields.CharField({ readOnly: true }),
  })
  static onboardingStepsRef = new fields.ArrayField({
    type: new fields.ModelField({ ModelClass: UserOnboardingStep, readOnly: true }),
  })
  static preferredVerticals = new fields.ArrayField({
    type: new fields.IdField({}),
  })
  static preferredOfferings = new fields.ArrayField({
    type: new fields.IdField({}),
  })
}
